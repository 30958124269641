import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  data() {
    return {
      requestType: 'POST',
    };
  },
  mounted() {
    this.getConfigList('page_list');
  },
  methods: {},
};
